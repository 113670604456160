import {Box, Carousel, useMobileBreakpoint} from '@customink/pigment-react';
import React, {useContext, useEffect, useState} from 'react';
import CurrentSelectedColorContext from 'product_details_page/Context/colorContext';
import ProductContext from 'product_details_page/Context/productContext';
import PreviewUploadBanner from './PreviewUploadBanner';

export default function ProductCarousel({
  buildColorImage,
  customDesignPreview
}) {
  const [slider, setSlider] = useState();
  const product = useContext(ProductContext);
  const {currentSelectedColor: currentColor} = useContext(
    CurrentSelectedColorContext
  );
  const {carouselProps, name: styleName} = product || {};

  if (!carouselProps || !currentColor) {
    return null;
  }

  const isMobile = useMobileBreakpoint();
  const breakpointCarouselHeight = () => (isMobile ? '465px' : '490px');

  const buildColorImageSet = (color) => {
    return `${buildColorImage(color, 412)} 1x, ${buildColorImage(color, 659)} 2x`;
  };

  const carouselImages = () => {
    const images = [
      <img
        alt={`${styleName}-default`}
        src={buildColorImage(currentColor)}
        srcSet={buildColorImageSet(currentColor)}
      />
    ];
    images.push(
      ...carouselProps.mainContent.map((media, idx) => {
        return (
          <img
            alt={styleName}
            src={media.src}
            srcSet={media.srcset}
            // eslint-disable-next-line react/no-array-index-key
            key={`carsl-img-${idx}`}
          />
        );
      })
    );
    return images;
  };

  const thumbnailImages = () => {
    const images = [
      <img
        alt={`${styleName}-default`}
        src={buildColorImage(currentColor)}
        srcSet={buildColorImageSet(currentColor)}
      />
    ];
    images.push(
      ...carouselProps.thumbnails.map((media, idx) => {
        return (
          <img
            alt={styleName}
            src={media.src}
            srcSet={media.srcset}
            // eslint-disable-next-line react/no-array-index-key
            key={`media-img-${idx}`}
          />
        );
      })
    );

    return images;
  };

  const onCreated = (_slider) => {
    setSlider(_slider);
  };

  useEffect(() => {
    if (slider) {
      slider.moveToIdx(0);
    }
  }, [currentColor, slider]);

  return (
    <Box
      sx={{
        display: {md: 'flow-root', sm: 'flex'},
        flexDirection: 'column',
        overflow: 'clip',
        paddingBottom: {md: '40px', sm: 0},
        width: {md: '45%', sm: '100%'}
      }}>
      {customDesignPreview && <PreviewUploadBanner />}
      <Carousel
        sx={{
          '.arrow': {
            position: 'relative',
            top: 0
          },
          ...(!isMobile && {
            position: 'sticky',
            top: '2.5rem'
          })
        }}
        sliderHeight={breakpointCarouselHeight()}
        slides={carouselImages()}
        onCreated={onCreated}
        {...(!isMobile && {
          thumbnails: thumbnailImages(),
          thumbSize: thumbnailImages().length > 6 ? 'small' : 'medium'
        })}
      />
    </Box>
  );
}
