import {Box, Button} from '@customink/pigment-react';
import React, {useState} from 'react';

const PLAForm = ({
  checkoutAction,
  currentSelectedColor,
  formAuthToken,
  sampleLinkClass,
  sampleLinkHref,
  sampleOrderType,
  samplePrice,
  sampleQuote,
  sampleQuoteCid,
  sizes,
  sizesList
}) => {
  const [size, setSize] = useState(sizesList?.length > 2 ? sizesList[0] : null);
  const [email, setEmail] = useState('');
  const [postalCode, setPostalCode] = useState('');

  const handleSizeChange = (event) => {
    setSize(event.target.value);
  };

  const handlePostalCodeChange = (event) => {
    setPostalCode(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  return (
    <>
      <a
        className={`pc-plaSampleLink ${sampleLinkClass}`}
        data-testid="pla-sample-link"
        href={sampleLinkHref}>
        <Box sx={{fontSize: '14px', textAlign: 'center'}}>
          <span className="sample-cta">{sampleQuote}</span>{' '}
          <span className="sample-price"> {samplePrice}</span>
        </Box>
      </a>
      <div className="pc-Style-hero-samples">
        <div className="pc-Style-reqSample">
          <form
            className="sb-Form pc-Style-samplesForm"
            action={checkoutAction}
            method="post">
            <input
              name="authenticity_token"
              value={formAuthToken}
              type="hidden"
            />
            <input
              id="order_type"
              name="order_type"
              value={sampleOrderType || ''}
              type="hidden"
            />
            <input
              id="design_cid"
              name="design_cid"
              value={sampleQuoteCid || ''}
              type="hidden"
            />
            <input
              id="product_id"
              name="product_id"
              value={currentSelectedColor.id}
              type="hidden"
            />
            <div className="sb-Form-group">
              <input
                data-testid="pla-email"
                type="text"
                id="email"
                name="email"
                value={email}
                onChange={(e) => handleEmailChange(e)}
                placeholder="Email"
                required
              />
            </div>
            <div className="pc-Style-samplesForm-group">
              <div className="sb-Form-group">
                <input
                  data-testid="pla-postal-code"
                  type="text"
                  name="postal_code"
                  id="postal_code"
                  value={postalCode}
                  onChange={(e) => handlePostalCodeChange(e)}
                  placeholder="Zip Code"
                  required
                />
              </div>
              <div className="sb-Form-group">
                <select
                  data-testid="pla-size-select"
                  value={size}
                  required
                  name="sizes"
                  onChange={(e) => handleSizeChange(e)}>
                  <option value="">Select a Size</option>
                  {sizes.map((s) => {
                    return (
                      <option key={`sz-opt-${s.name}`} value={s.name}>
                        {s.conciseName}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="sb-Form-group sb-Form-group--buttons">
              <Button
                data-testid="pla-submit"
                name="commit"
                value="Proceed to Checkout"
                disable_with="Adding to Cart..."
                className="sb-Btn sb-Btn--block sb-Btn--secondary"
                data-disable-with="Proceed to Checkout"
                variant="secondary"
                type="submit">
                Proceed to Checkout
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default PLAForm;
