import {Box, LightBulbIcon, Typography} from '@customink/pigment-react';
import React from 'react';

const PreviewUploadBanner = () => {
  return (
    <Box
      alignItems="center"
      alignSelf="stretch"
      display="flex"
      flexDirection="column"
      whiteSpace="nowrap"
      gap="4px"
      padding="4px 8px">
      <Typography
        fontSize="13px"
        color="#FA3C00"
        borderRadius="32px"
        justifyContent="center"
        alignItems="center"
        display="flex"
        padding="4px 8px"
        gap="4px"
        backgroundColor="rgba(250, 60, 0, 0.11)">
        <LightBulbIcon sx={{height: '16px', width: '16px'}} />
        You can refine your design in the next step
      </Typography>
    </Box>
  );
};

export default PreviewUploadBanner;
