import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography
} from '@customink/pigment-react';
import {useMobileBreakpoint} from '@customink/pigment-react/lib/utils/useBreakpoint';
import React from 'react';
import CustomExpandIcon from './CustomExpandIcon';

const ProductDetailsRow = ({
  accordionExpanded,
  accordionSummary,
  accordionWrapperSx = {},
  children,
  childrenRef,
  defaultExpanded = false,
  desktopVersionSx,
  onAccordionClick,
  title,
  useAccordionAlways = false
}) => {
  const isMobile = useMobileBreakpoint();
  const dataTestId = title.toLowerCase().replace(/ /g, '-');

  return isMobile || useAccordionAlways ? (
    <Box
      data-testid={dataTestId}
      ref={childrenRef}
      sx={{
        backgroundColor: 'white',
        px: '1rem',
        ...accordionWrapperSx
      }}>
      <Accordion
        disableGutters
        defaultExpanded={defaultExpanded}
        expanded={accordionExpanded}
        onClick={onAccordionClick}
        sx={{
          backgroundColor: '#f4f4f4', // TODO: Add color to Pigment theme (gray light)
          boxShadow: 'none'
        }}>
        <AccordionSummary expandIcon={<CustomExpandIcon />}>
          {React.isValidElement(accordionSummary) ? (
            accordionSummary
          ) : (
            <Typography variant="subHeading" fontWeight={600}>
              {accordionSummary}
            </Typography>
          )}
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    </Box>
  ) : (
    <Box
      data-testid={dataTestId}
      ref={childrenRef}
      sx={{
        fontSize: '0.825rem',
        margin: '0 auto',
        maxWidth: '81.25rem',
        width: '90%',
        ...desktopVersionSx
      }}>
      <Typography variant="h6">{title}</Typography>
      {children}
    </Box>
  );
};

export default ProductDetailsRow;
