import React, { useEffect, useState, useRef } from 'react';
import {QueryClient, QueryClientProvider} from 'react-query';
import {PigmentThemeProvider, Box} from '@customink/pigment-react';
import ProductHero from 'product_details_page/ProductHero';
import MoreInfo from 'product_details_page/MoreInfo';
import RelatedProducts from 'product_details_page/RelatedProducts';
import ProductReviews from 'product_details_page/ProductReviews';
import CurrentSelectedColorContext from 'product_details_page/Context/colorContext';
import ProductContext from 'product_details_page/Context/productContext';
import { getOctoIdFromCookie } from '../algolia_listing_pages/utils/data_utils';

const ProductDetailsPage = (props) => {
  const [currentSelectedColor, setCurrentSelectedColor] = useState(
    props.productProps.selectedColor
  );
  const [reviewsAccordionState, setReviewsAccordionState] = useState(false);
  const [similarAccordionState, setSimilarAccordionState] = useState(false);
  const reviewRef = useRef(null);
  const similarRef = useRef(null);
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: false
      }
    }
  })
  const { name, productInfo } = props.productProps || {};
  const oeUserToken = getOctoIdFromCookie();
  if (oeUserToken) {
    window.dataLayer.push({ algoliaUserToken: oeUserToken })
  }

  return (
    <QueryClientProvider client={queryClient}>
      <PigmentThemeProvider>
        <ProductContext.Provider
          value={{...props.productProps, mainImage: props.mainImage, categoryInfo: props.categoryInfo }}>
          <CurrentSelectedColorContext.Provider
            value={{
              currentSelectedColor,
              setCurrentSelectedColor,
              reviewsAccordionState,
              setReviewsAccordionState,
              similarAccordionState,
              setSimilarAccordionState
            }}>
            <div className="pc-Style-hero pc-Style-section pc-Style-section--extended pc-Style-section--first">
              <div className="pc-Style">
                <ProductHero
                  {...props.productProps}
                  mainImage={props.mainImage}
                  ttarpNumber={props.ttarpNumber}
                  iframed={props.iframed}
                  reviewRef={reviewRef}
                  similarRef={similarRef}
                  sampleQuote={props.sampleQuote}
                  samplePrice={props.samplePrice}
                  sampleLinkClass={props.sampleLinkClass}
                  sampleLinkHref={props.sampleLinkHref}
                  sampleOrderType={props.sampleOrderType}
                  checkoutAction={props.checkoutAction}
                  sampleQuoteCid={props.sampleQuoteCid}
                  renderSampleForm={props.renderSampleForm}
                  formAuthToken={props.formAuthToken}
                  blanksOrdering={props.blanksOrdering}
                  productProps={props.productProps}
                />
              </div>
            </div>
            <Box sx={{
              pb: '2rem',
              display: 'flex',
              flexDirection: 'column',
              gap: {md: '2.5rem', sm: '1rem'},
              mt: { md: 0, sm: '1rem'}}}>
              <ProductReviews ref={reviewRef} />
              <RelatedProducts ref={similarRef} />
              {productInfo && <MoreInfo name={name} productInfo={productInfo} />}
            </Box>
          </CurrentSelectedColorContext.Provider>
        </ProductContext.Provider>
      </PigmentThemeProvider>
    </QueryClientProvider>
  );
};

export default ProductDetailsPage;
