import {
  Box,
  Card,
  CardContent,
  Rating,
  Typography
} from '@customink/pigment-react';
import {CardActionArea} from '@customink/pigment-react/lib/components/Card';
import React from 'react';

const ProductDetailsCard = ({
  complimentBadge,
  imageSrc,
  imageSrcSet,
  linkHref,
  minQty,
  name,
  onClick,
  ratingCount,
  ratingScore,
  sizing
}) => {
  return (
    <Card sx={{height: {md: '420px', sm: '140px'}}}>
      <CardActionArea
        href={linkHref}
        onClick={onClick}
        sx={{
          alignItems: {md: 'center', sm: 'flex-start'},
          display: 'flex',
          flexDirection: {md: 'column', sm: 'row'},
          height: '100%',
          justifyContent: {md: 'center', sm: 'flex-start'},
          pt: 0
        }}>
        <CardContent
          sx={{
            alignItems: 'center',
            display: 'flex',
            height: {md: 'auto', sm: '100%'},
            justifyContent: 'center',
            mt: 0,
            pl: {sm: '0.5rem'},
            pr: {sm: 0}
          }}>
          <Box
            component="img"
            sx={{
              borderRadius: {md: '0', sm: '1.5625rem'},
              height: {md: '17.375rem', sm: '6rem'},
              // mt: {md: 0, sm: '1rem'},
              outline: {md: 'none', sm: '1px solid #f4f4f4'},
              width: {md: '100%', sm: '6rem'}
            }}
            src={imageSrc}
            srcSet={imageSrcSet}
            alt={name}
          />
        </CardContent>
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '0.3125rem',
            height: '100%',
            justifyContent: {sm: 'center'},
            mt: 0,
            pb: {md: '1rem', sm: 0},
            px: {md: '1.5rem', sm: '1rem'}
          }}>
          <Typography variant="bodyLong1">{name}</Typography>
          <Rating
            precision={0.1}
            value={Number(ratingScore)}
            ratingCount={ratingCount}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%'
            }}>
            <Typography variant="captionRegular">{sizing}</Typography>
            <Typography variant="captionRegular">&ensp;•&ensp;</Typography>
            <Typography variant="captionRegular">
              {minQty === 1 ? 'No Minimum' : `Minimum ${minQty}`}
            </Typography>
          </Box>
          {complimentBadge && complimentBadge.toUpperCase()}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default ProductDetailsCard;
