import {Rating, Stack, Typography} from '@customink/pigment-react';
import {useMobileBreakpoint} from '@customink/pigment-react/lib/utils/useBreakpoint';
import React, {forwardRef, useContext, useEffect, useMemo, useRef} from 'react';
import useProductReviewSummarization from 'hooks/queries/reviewSummarizations';
import useTrustPilotProductReviews from 'hooks/queries/trustPilotProductReviews';
import {reviewSummarization as reviewSummarizationFeatureFlag} from '../utils/constants/signalmanConstants';
import CurrentSelectedColorContext from './Context/colorContext';
import ProductContext from './Context/productContext';
import ProductDetailsRow from './ProductDetailsRow';

const ProductReviews = forwardRef(function ProductReviews(_, reviewsRef) {
  const {colors, id, name, ratingValue} = useContext(ProductContext);
  const trustPilotRef = useRef(null);
  const isMobile = useMobileBreakpoint();
  const isSummarizationTurnedOn = useMemo(
    () => reviewSummarizationFeatureFlag === 'test',
    []
  );
  const {reviewsAccordionState, setReviewsAccordionState} = useContext(
    CurrentSelectedColorContext
  );
  const skuList = colors?.map((color) => color.id);
  const {data: trustPilotProductReviews} = useTrustPilotProductReviews(skuList);
  const {data: reviewSummarization} = useProductReviewSummarization(id);
  const hasProductReviews =
    trustPilotProductReviews?.numberOfReviews?.total > 0;

  useEffect(() => {
    if (window.Trustpilot && trustPilotRef.current) {
      window.Trustpilot.loadFromElement(trustPilotRef.current, true);
    }
  }, [isMobile]);

  if (!hasProductReviews) {
    return null;
  }

  return (
    <ProductDetailsRow
      title={`Reviews for ${name}`}
      desktopVersionSx={{backgroundColor: 'white', padding: '1rem'}}
      childrenRef={reviewsRef}
      accordionExpanded={reviewsAccordionState}
      onAccordionClick={() => setReviewsAccordionState(!reviewsAccordionState)}
      accordionSummary={
        <Typography
          variant="subHeading"
          fontWeight={600}
          sx={{alignItems: 'center', display: 'flex'}}>
          Reviews&nbsp;
          <Rating precision={0.1} value={Number(ratingValue)} />
        </Typography>
      }>
      <Stack spacing={4}>
        {isSummarizationTurnedOn && reviewSummarization?.summary && (
          <Stack>
            <Typography variant="h4" sx={{borderBottom: 'none !important'}}>
              Customer says
            </Typography>
            <Typography variant="bodyLong1">
              {reviewSummarization.summary}
            </Typography>
            <Typography variant="captionRegular" fontWeight="bold" sx={{pt: 2}}>
              AI-generated from customer reviews
            </Typography>
          </Stack>
        )}
        <div
          ref={trustPilotRef}
          className="trustpilot-widget"
          data-locale="en-US"
          data-template-id="57177697fdb1180308e3815f"
          data-businessunit-id={process.env.TRUST_PILOT_BU_ID}
          data-style-height="700px"
          data-style-width="100%"
          data-theme="light"
          data-sku={skuList}
          data-review-languages="en"
          data-no-reviews="hide"
          data-fullwidth="true"
          data-star-color="#FA3C00"
          data-text-color="#181818"
          data-link-color="#2954C7">
          <a
            href="https://www.trustpilot.com/review/www.customink.com"
            target="_blank"
            rel="noopener noreferrer">
            Trustpilot
          </a>
        </div>
      </Stack>
    </ProductDetailsRow>
  );
});

export default ProductReviews;
