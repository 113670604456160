import {Box, Skeleton} from '@customink/pigment-react';
import React from 'react';
import DeliveryInfo from '../../components/DeliveryInfo';
import useProductDeliveryOptions from '../../hooks/queries/productDeliveryOptions';

const DeliveryLoading = () => {
  return (
    <Box
      data-testid="delivery-info-loading"
      sx={{display: 'inline-flex', flexDirection: 'row', gap: '0.5rem'}}>
      <Skeleton height="2.5rem" variant="circular" width="2.5rem" />
      <Box sx={{display: 'flex', flexDirection: 'column', gap: '0.25rem'}}>
        <Skeleton height="1rem" width="6.25rem" />
        <Skeleton height="1rem" width="6rem" />
      </Box>
    </Box>
  );
};

const getFormattedDeliveryDate = (deliveryDate) => {
  if (deliveryDate) {
    const [year, month, day] = deliveryDate.split('-');
    const date = new Date(year, month - 1, day);
    return date.toLocaleDateString('en-US', {
      day: 'numeric',
      month: 'short',
      weekday: 'short'
    });
  }
  return null;
};

const ElevateSuperRushDelivery = ({styleId}) => {
  const {deliveryDetails, error, isLoading} =
    useProductDeliveryOptions(styleId);
  const {rush, standard, super_rush: superRush} = deliveryDetails || {};

  if (error) {
    return null;
  }

  return (
    <Box
      data-testid="elevate-super-rush-delivery"
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: '1.5rem'
      }}>
      {isLoading ? (
        <>
          <DeliveryLoading />
          <DeliveryLoading />
          <DeliveryLoading />
        </>
      ) : (
        <>
          {standard && (
            <DeliveryInfo
              variant="standard"
              dateStr={getFormattedDeliveryDate(standard.delivery_date)}
            />
          )}
          {rush && (
            <DeliveryInfo
              variant="rush"
              dateStr={getFormattedDeliveryDate(rush.delivery_date)}
            />
          )}
          {superRush && (
            <DeliveryInfo
              variant="superRush"
              dateStr={getFormattedDeliveryDate(superRush.delivery_date)}
            />
          )}
        </>
      )}
    </Box>
  );
};

export default ElevateSuperRushDelivery;
