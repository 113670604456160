import {Box, Typography} from '@customink/pigment-react';
import React, {useContext} from 'react';
import addColorToStylePath from '../../utils/addColorToStylePath';
import CurrentSelectedColorContext from '../Context/colorContext';
import ProductDetailsRow from '../ProductDetailsRow';
import ProductDetailsCard from './RelatedProductCard';

const AlternativeProducts = ({alternativeProducts, similarRef}) => {
  const {setSimilarAccordionState, similarAccordionState} = useContext(
    CurrentSelectedColorContext
  );
  return (
    <ProductDetailsRow
      childrenRef={similarRef}
      accordionSummary="Similar Styles"
      accordionExpanded={similarAccordionState}
      onAccordionClick={() => {
        setSimilarAccordionState(!similarAccordionState);
      }}
      title="Similar Styles">
      <Typography variant="bodyLong1" className="alternative-heading-body">
        Make sure you&apos;ve got enough for everyone with these styles that
        have a similar fit, feel, and color selection.
      </Typography>
      <Box
        sx={{
          display: 'grid',
          gap: '1rem',
          gridTemplateColumns: 'repeat(auto-fit, minmax(240px, 1fr))',
          mt: '1rem',
          rowGap: '0.625rem'
        }}>
        {alternativeProducts.map((alternative) => (
          <ProductDetailsCard
            key={`alternative-${alternative.name}`}
            complimentBadge={alternative.complement_badge}
            imageSrc={alternative.image_src}
            imageSrcSet={alternative.image_srcset}
            linkHref={addColorToStylePath(alternative.link, alternative.color)}
            minQty={alternative.min_qty}
            name={alternative.name}
            ratingCount={alternative.rating_count}
            ratingScore={alternative.rating_score}
            sizing={alternative.sizing}
          />
        ))}
      </Box>
    </ProductDetailsRow>
  );
};

export default AlternativeProducts;
