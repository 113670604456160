import {Button} from '@customink/pigment-react';
import React, {useContext} from 'react';
import * as urlSetup from '../../../src/utils/url';
import labLink from '../../../utils/labLink';
import postMessageForLab from '../../../utils/labMessage';
import CurrentSelectedColorContext from '../../Context/colorContext';
import ProductContext from '../../Context/productContext';

const SelectProductOrDesignButton = ({
  customDesignPreview,
  iframed,
  singles,
  sizes,
  sizesList
}) => {
  const {
    categoryInfo,
    decoration,
    id,
    legacyStyleId,
    minQty,
    name,
    primaryCategoryId
  } = useContext(ProductContext);

  const {currentSelectedColor: currentColor} = useContext(
    CurrentSelectedColorContext
  );

  const handleLabLinkIframe = (e) => {
    e.preventDefault();
    const productInfo = {
      decoration,
      defaultCategoryId: primaryCategoryId,
      minQty,
      name: currentColor.name,
      productId: currentColor.legacyColorId,
      size_range: `${sizes[0].conciseName}-${sizes[sizes.length - 1].conciseName}`,
      sizes: sizesList.join(', ')
    };
    const payload = {
      categoryPath: categoryInfo.path,
      cid: currentColor.loadLabCid,
      colorId: urlSetup.getQueryParam(
        'PK',
        labLink(
          currentColor.legacyColorId,
          singles,
          currentColor.loadLabCid,
          legacyStyleId
        )
      ),
      data: [
        currentColor.id,
        productInfo,
        id,
        name,
        categoryInfo.id,
        categoryInfo.name
      ],
      event: 'catalog:product-selected',
      keywords: urlSetup.getQueryParam(
        'KW',
        labLink(
          currentColor.legacyColorId,
          singles,
          currentColor.loadLabCid,
          legacyStyleId
        )
      ),
      singles,
      styleId: id
    };
    postMessageForLab(payload);
  };

  const href = labLink(
    currentColor.legacyColorId,
    singles,
    currentColor.loadLabCid,
    legacyStyleId
  );

  const buttonProps = {
    'data-testid': 'select-product-or-start-design',
    fullWidth: true,
    ...(iframed
      ? {
          'data-href': href,
          href: '#',
          onClick: handleLabLinkIframe
        }
      : {
          href
        })
  };
  const buttonValue = () => {
    if (iframed) return 'Select Product';
    if (customDesignPreview) return 'Continue to Design & Pricing';
    return 'Start Designing';
  };

  return <Button {...buttonProps}>{buttonValue()}</Button>;
};

export default SelectProductOrDesignButton;
