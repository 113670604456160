export const trackReadReviewsClickEvent = () => {
  CustomInk.Metrics.fireEvent('product details page', 'read reviews', 'click');
};

export const trackOrderFewerThanSixEvent = (enabled) => {
  const label = enabled ? 'on' : 'off';
  CustomInk.Metrics.fireEvent(
    'product details page',
    'order fewer than six',
    label
  );
};

export const trackRequestSampleHoverEvent = () => {
  CustomInk.Metrics.fireEvent(
    'product details page',
    'sample request',
    'hover'
  );
};
